import {
	AlreadySubscribedError,
	NotSubscribedError,
} from './StateRegistryErrors';
import { createStore } from 'redux';

/**
 * @ignore
 */
class Store {
	/**
	 * Creates a store with `initialData` and `actionFunctions`.
	 * @param {Object} initialData - The initial state.
	 * @param {Object<string, function>} actionFunctions - An object of functionName/function pairs.
	 * @param {string} nameOfStore - the name of the store
	 */
	constructor(initialData, actionFunctions, nameOfStore) {
		// The Redux reducer maps action.type to `actionFunctions` and calls them.
		// Redux will call a reducer with an undefined state to test it, even if initialData is passed.
		// So, it returns null per default.
		const reducer = function (state = null, action) {
			if (typeof actionFunctions[action.type] === 'function') {
				return actionFunctions[action.type](state, action.payload);
			}

			return state;
		};

		this._store = createStore(
			reducer,
			initialData,
			window.__REDUX_DEVTOOLS_EXTENSION__ &&
				window.__REDUX_DEVTOOLS_EXTENSION__(),
		);
		this._nameOfStore = nameOfStore;
		this._subscriptions = [];
	}

	/**
	 * Subscribes `callbackFunction` to the store.
	 * The given callback function is called, every time the state of the store changes.
	 * Stores the subscription for a later unsubscribe.
	 * @param {function} callbackFunction - A named callback.
	 * @returns {void}
	 * @throws {AlreadySubscribedError} Will throw an error if `callbackFunction` has already been subscribed.
	 */
	subscribe(callbackFunction) {
		const filteredSubscriptions = this._subscriptions.filter(
			(subscription) => subscription.callback === callbackFunction,
		);

		if (filteredSubscriptions.length !== 0) {
			throw new AlreadySubscribedError(callbackFunction.name);
		}

		const unsubscribe = this._store.subscribe(() => {
			const state = this._store.getState();

			callbackFunction(state, this._nameOfStore);
		});

		this._subscriptions.push({
			callback: callbackFunction,
			unsubscribe: unsubscribe,
		});
	}

	/**
	 * Unsubscribes `callbackFunction` from the store.
	 * @param {function} callbackFunction - A named callback.
	 * @returns {void}
	 * @throws {NotSubscribedError} Will throw an error if `callbackFunction` has not been subscribed before.
	 */
	unsubscribe(callbackFunction) {
		const subscriptionsLength = this._subscriptions.length;

		for (let index = 0; index < subscriptionsLength; index += 1) {
			const subscription = this._subscriptions[index];

			if (subscription.callback === callbackFunction) {
				subscription.unsubscribe();
				this._subscriptions.splice(index, 1);
				return;
			}
		}

		// `callbackFunction` was not found in `this._subscriptions`
		throw new NotSubscribedError(callbackFunction.name);
	}

	/**
	 * Triggers `nameOfAction` with `parameterObject` as payload.
	 * @param {string} nameOfAction - the name of the action function.
	 * @param {Object} [parameterObject] - the payload of the action.
	 * @returns {void}
	 */
	triggerAction(nameOfAction, parameterObject) {
		// Redux needs a type attribute, so this function maps nameOfAction to type.
		this._store.dispatch({
			payload: parameterObject,
			type: nameOfAction,
		});
	}

	/**
	 * Returns state from store.
	 * @returns {void}
	 */
	getState() {
		return this._store.getState();
	}
}

export default Store;
