class StorageTypes {
	static get SESSION_STORAGE() {
		return 'sessionStorage';
	}
	static get LOCAL_STORAGE() {
		return 'localStorage';
	}
	static get LIFETIME_UNLIMITED() {
		return -1;
	}
	static get LIFETIME_ONE_DAY() {
		return 24 * 60 * 60 * 1000; /* one day in milliseconds */
	}
}

export default StorageTypes;
