/**
 * Error that indicates that a callback function has already been subscribed.
 * @param {string} [functionName] - The name of the function.
 * @constructor
 */
function AlreadySubscribedError(functionName = '') {
	this.name = 'AlreadySubscribedError';

	if (functionName === '') {
		this.message = 'Callback function has already been subscribed.';
	} else {
		this.message = `bad parameter \`${functionName}\`: callback function has already been subscribed.`;
	}
}

/**
 * Error that indicates that a callback function has not been subscribed before.
 * @param {string} [functionName] - The name of the function.
 * @constructor
 */
function NotSubscribedError(functionName = '') {
	this.name = 'NotSubscribedError';

	if (functionName === '') {
		this.message = 'Callback function has not been subscribed before.';
	} else {
		this.message = `bad parameter \`${functionName}\`: callback function has not been subscribed before.`;
	}
}

/*
 * Built-in classes such as Date, Array, DOM etc cannot be properly subclassed due to limitations in ES5.
 * (https://babeljs.io/docs/usage/caveats/#classes)
 */
NotSubscribedError.prototype = Object.create(Error.prototype);
AlreadySubscribedError.prototype = Object.create(Error.prototype);

export { NotSubscribedError, AlreadySubscribedError };
