import Store from './Store';

/**
 * Manages multiple stores by name.
 */
class State {
	constructor() {
		if (State._instance) {
			return State._instance;
		}

		this._stores = [];

		State._instance = this;
	}

	/**
	 * Returns the store with name `nameOfStore`.
	 * Returns null if the store does not exist.
	 * @param {string} nameOfStore - The name of the store.
	 * @returns {Store | null} - The instance of the Store.
	 */
	getStore(nameOfStore) {
		if (typeof this._stores[nameOfStore] === 'undefined') {
			return null;
		}

		return this._stores[nameOfStore];
	}

	/**
	 * Creates a new instance of Store and saves it in an array of nameOfStore/store pairs.
	 * Throws an error if a store with the same name is created.
	 * @param {string} nameOfStore - The name of the store.
	 * @param {Object} initialData - The initial state of the store.
	 * @param {Object<string, function>} actionFunctions - An object of functionName/function pairs.
	 * @returns {void}
	 * @throws Will throw an error if the store with name `nameOfStore` already exists.
	 */
	setStore(nameOfStore, initialData, actionFunctions) {
		if (typeof this._stores[nameOfStore] !== 'undefined') {
			throw new Error(
				`Store with name \`${nameOfStore}\` already exists.`,
			);
		}

		this._stores[nameOfStore] = new Store(
			initialData,
			actionFunctions,
			nameOfStore,
		);
	}
}

export default State;
